<template>
      <div class="square">
            <entrance :sitedata="true" @changTip="changTip"></entrance>
            <div class="title flex-align-center">课程广场</div>
            <div class="main">
                  <div class="column" v-if="state">
                        <div class="columnNav flex-layout">
                              <div class="columnNavbox flex-column-center" v-for="(item, index) in navIcon"
                                    :key="index">
                                    <img :src="columnIndex == index ? item.icon2 : item.icon1"
                                          @click="icontype(index)" />
                                    <span :style="{ color: columnIndex == index ? item.style : '' }"
                                          @click="icontype(index)">{{ item.name }}</span>
                              </div>
                        </div>
                        <div class="column_item">
                              <el-carousel direction="vertical" :autoplay="false" ref="carousel"
                                    indicator-position="none">
                                    <el-carousel-item v-for="(item,index) in someList" :key="index">
                                          <!-- <div class="columnSon" v-for="(itemSon,indexSon) in item.data"
                                                :key="indexSon">
                                                {{itemSon.name}}
                                          </div> -->
                                          <div class="columnSon" :class="itemSon.class"
                                                :style="{ backgroundImage: 'url(' + itemSon.img + ')' }"
                                                v-for="(itemSon,indexSon) in item.data" :key="indexSon"
                                                @click="particulars(itemSon.name)">
                                                <span>
                                                      {{ itemSon.name }}
                                                </span>
                                          </div>
                                    </el-carousel-item>

                              </el-carousel>
                              <div class="focusDom">
                                    <div class="focus" v-for="i in 2" :key="i" @click="add(i)">
                                    </div>
                              </div>
                        </div>
                        <!-- <div class="column_item">

                              <div class="columnSon" :class="[item.class]"
                                    :style="{ backgroundImage: 'url(' + item.img + ')' }"
                                    v-for="(item, index) in attribute[appear]" :key="index"
                                    @click="particulars(item.name)">
                                    <span>
                                          {{ item.name }}
                                    </span>
                              </div>
                        </div> -->
                  </div>
                  <router-view v-else></router-view>
            </div>
            <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
      </div>
</template>

<script>
      import {
            slider,
            slideritem
      } from 'vue-concise-slider'
      import entrance from "../../Common_components/head/entrance";
      import {
            mapState,
            mapMutations
      } from "vuex";
      export default {
            data() {
                  return {
                        centerDialogVisible2: false,
                        someList: [{

                                    data: [
                                          {
                                                name: "生活中的吉语",
                                                img: require(
                                                      "../../assets/courseSquare/column_maobi1.png"
                                                ),
                                                class: "item1",
                                          },
                                          {
                                                name: "你不知道的青铜器·利簋",
                                                img: require(
                                                      "../../assets/courseSquare/column_archaeology.png"
                                                ),
                                                class: "item2",
                                          },
                                          {
                                                name: "五色鹦鹉图",
                                                img: require(
                                                      "../../assets/courseSquare/column_guohua.png"
                                                ),
                                                class: "item2",
                                          },
                                          {
                                                name: "动物王国开大会",
                                                img: require(
                                                      "../../assets/courseSquare/column_yuwen1.png"
                                                ),
                                                class: "item2",
                                          },
                                          {
                                                name: "稻草人",
                                                img: require(
                                                      "../../assets/courseSquare/column_yuwen2.png"
                                                ),
                                                class: "item2",
                                          },
                                          {
                                                name: "妙笔生春（4节）",
                                                img: require(
                                                      "../../assets/courseSquare/column_yingbi.png"
                                                ),
                                                class: "item3",
                                          },

                                          {
                                                name: "《经典阅读·水浒传》（4节）",
                                                img: require(
                                                      "../../assets/courseSquare/column_yuedu.png"
                                                ),
                                                class: "item2",
                                          },
                                          {
                                                name: "名家书法经典课（3节）",
                                                img: require(
                                                      "../../assets/courseSquare/column_maobi2.png"
                                                ),
                                                class: "item2",
                                          },


                                    ]
                              },
                              {
                                    html: 'slide2',
                                    style: {
                                          'background': '#4bbfc3'
                                    },
                                    data: [{
                                          name: "名家书法经典课（3节）",
                                          img: require(
                                                "../../assets/courseSquare/column_maobi2.png"
                                          ),
                                          class: "item2",
                                    }, {
                                          name: "名家书法经典课（3节）",
                                          img: require(
                                                "../../assets/courseSquare/column_maobi2.png"
                                          ),
                                          class: "item2",
                                    }, {
                                          name: "名家书法经典课（3节）",
                                          img: require(
                                                "../../assets/courseSquare/column_maobi2.png"
                                          ),
                                          class: "item2",
                                    }, {
                                          name: "名家书法经典课（3节）",
                                          img: require(
                                                "../../assets/courseSquare/column_maobi2.png"
                                          ),
                                          class: "item2",
                                    }, {
                                          name: "名家书法经典课（3节）",
                                          img: require(
                                                "../../assets/courseSquare/column_maobi2.png"
                                          ),
                                          class: "item2",
                                    }, ]
                              },
                        ],
                        //Slider configuration [obj]
                        options: {
                              currentPage: 0,
                              direction: 'vertical'
                        },
                        attribute: {
                              推荐: [{
                                          name: "生活中的吉语",
                                          img: require("../../assets/courseSquare/column_maobi1.png"),
                                          class: "item1",
                                    },
                                    {
                                          name: "你不知道的青铜器·利簋",
                                          img: require("../../assets/courseSquare/column_archaeology.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "五色鹦鹉图",
                                          img: require("../../assets/courseSquare/column_guohua.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "动物王国开大会",
                                          img: require("../../assets/courseSquare/column_yuwen1.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "稻草人",
                                          img: require("../../assets/courseSquare/column_yuwen2.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "妙笔生春（4节）",
                                          img: require("../../assets/courseSquare/column_yingbi.png"),
                                          class: "item3",
                                    },

                                    {
                                          name: "《经典阅读·水浒传》（4节）",
                                          img: require("../../assets/courseSquare/column_yuedu.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "名家书法经典课（3节）",
                                          img: require("../../assets/courseSquare/column_maobi2.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "名家书法经典课（3节）",
                                          img: require("../../assets/courseSquare/column_maobi2.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "名家书法经典课（3节）",
                                          img: require("../../assets/courseSquare/column_maobi2.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "名家书法经典课（3节）",
                                          img: require("../../assets/courseSquare/column_maobi2.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "名家书法经典课（3节）",
                                          img: require("../../assets/courseSquare/column_maobi2.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "名家书法经典课（3节）",
                                          img: require("../../assets/courseSquare/column_maobi2.png"),
                                          class: "item2",
                                    },
                              ],
                              书法: [{
                                          name: "粉笔（49节）",
                                          img: require("../../assets/courseSquare/calligraphy_fenbi.png"),
                                          class: "item3",
                                    },
                                    {
                                          name: "毛笔（32节）",
                                          img: require("../../assets/courseSquare/calligraphy_maobi.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "硬笔（16节）",
                                          img: require("../../assets/courseSquare/calligraphy_yingbi.png"),
                                          class: "item2",
                                    },
                              ],
                              绘画: [{
                                          name: "国画山水（49节）",
                                          img: require("../../assets/courseSquare/drawing_shanshui.png"),
                                          class: "item3",
                                    },
                                    {
                                          name: "国画花鸟（32节）",
                                          img: require("../../assets/courseSquare/drawing_huaniao.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "国画人物（16节）",
                                          img: require("../../assets/courseSquare/drawing_renwu.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "色彩（16节）",
                                          img: require("../../assets/courseSquare/drawing_secai.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "动漫（16节）",
                                          img: require("../../assets/courseSquare/drawing_dongman.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "素描（48节）",
                                          img: require("../../assets/courseSquare/drawing_shumiao.png"),
                                          class: "item2",
                                    },
                              ],
                              阅读: [{
                                          name: "全本阅读指导（低年段）（15节）",
                                          img: require("../../assets/courseSquare/read_di.png"),
                                          class: "item3",
                                    },
                                    {
                                          name: "中年段（15节）",
                                          img: require("../../assets/courseSquare/read_zhong.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "高年段（15节）",
                                          img: require("../../assets/courseSquare/read_zhong.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "1-6年级（12节）",
                                          img: require("../../assets/courseSquare/read_xiao.png"),
                                          class: "item2",
                                    },
                              ],
                              赏析: [{
                                          name: "艺术世界（15节）",
                                          img: require("../../assets/courseSquare/impressions_yishu.png"),
                                          class: "item3",
                                    },
                                    {
                                          name: "自然科学（15节）",
                                          img: require("../../assets/courseSquare/impressions_ziran.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "人文历史（15节）",
                                          img: require("../../assets/courseSquare/impressions_renwen.png"),
                                          class: "item2",
                                    },
                                    {
                                          name: "世界风光（12节）",
                                          img: require("../../assets/courseSquare/impressions_shijian.png"),
                                          class: "item2",
                                    },
                              ],
                              更多: [],
                        },
                        navIcon: [{
                                    name: "推荐",
                                    icon1: require("../../assets/courseSquare/recommend1.png"),
                                    icon2: require("../../assets/courseSquare/recommend2.png"),
                                    style: "#FCE33B",
                              },
                              {
                                    name: "书法",
                                    icon1: require("../../assets/courseSquare/calligraphy1.png"),
                                    icon2: require("../../assets/courseSquare/calligraphy2.png"),
                                    style: "#FEAF7E",
                              },
                              {
                                    name: "绘画",
                                    icon1: require("../../assets/courseSquare/drawing1.png"),
                                    icon2: require("../../assets/courseSquare/drawing2.png"),
                                    style: "#C5ACFE",
                              },
                              {
                                    name: "阅读",
                                    icon1: require("../../assets/courseSquare/read1.png"),
                                    icon2: require("../../assets/courseSquare/read2.png"),
                                    style: "#FE95AB",
                              },
                              {
                                    name: "赏析",
                                    icon1: require("../../assets/courseSquare/idea1.png"),
                                    icon2: require("../../assets/courseSquare/idea2.png"),
                                    style: "#FE87B9",
                              },
                              {
                                    name: "更多",
                                    icon1: require("../../assets/courseSquare/more1.png"),
                                    icon2: require("../../assets/courseSquare/more2.png"),
                                    style: "#3BD967",
                              },
                        ],
                        columnIndex: 0,
                        appear: "推荐",
                        state: true,
                  };
            },
            components: {
                  entrance,
                  slider,
                  slideritem
            },
            //方法
            methods: {
                  ...mapMutations(["hint_data"]),
                  changTip(value) {
                        if (value) {
                              this.hint_data("暂无活动");
                              this.centerDialogVisible2 = true;
                        }
                  },
                  clear(bool) {
                        this.centerDialogVisible2 = bool;
                  },
                  changTip(value) {
                        if (value) {
                              this.hint_data("暂无活动");
                              this.centerDialogVisible2 = true;
                        }
                  },
                  icontype(index) {
                        this.columnIndex = index;
                        this.appear = this.navIcon[index].name;
                  },
                  particulars(value) {
                        this.state = false;
                        this.$router.push({
                              path: "square/sector",
                              query: {
                                    name: value,
                              },
                        });
                  },
                  add(index) {
                        this.$refs.carousel.setActiveItem(index - 1);
                  }
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {},
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  if (this.$route.path == "/square/sector") {
                        this.state = false;
                  } else {
                        this.state = true;
                  }
            },
      };
</script>
<style lang='less' scoped>
      @import "./less/square";
</style>